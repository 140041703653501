<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :fiscal="formattedItem.fiscal"
            :postal="formattedItem.postal"
            :servicio="formattedItem.servicio"
          />
        </template>
      </b10-view-summary>
      <b10-fab-button
        v-if="hasEditPerm(permisoUtilizado)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'
import Data from './ClienteDireccionViewData'
import ExtraSubtitle from '../components/ExtraSubtitle'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    tipo: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'otros_datos', label: 'Otros datos'},
        { name: 'tdireccion_descripcion', label: 'Tipo de dirección' },
        { name: 'localidad', label: 'Localidad' },
        { name: 'provincia', label: 'Provincia' },
        { name: 'cp', label: 'Códio postal' },
        { name: 'observacion', label: 'Observaciones' },
        { name: 'latitud', label: 'Latitud' },
        { name: 'longitud', label: 'Longitud' },
      ],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        geolocalizar: {
          title: 'Capturar geolocalización actual',
          visible: true,
          icon: 'mapLocation',
        },
      },
      permisoUtilizado: null,
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcliente_direccion
        item.title = this.$online.clienteDireccion.title(item)
        item.subtitle = this.$online.clienteDireccion.subtitle(item)
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (this.tipo === 'cliente_potencial') {
        this.permisoUtilizado = this.permissions.clientePotencialDireccion.id
      } else if (this.tipo === 'cliente') {
        this.permisoUtilizado = this.permissions.clienteDireccion.id
      }
      this.toolbarOptions.geolocalizar.visible = this.hasEditPerm(this.permisoUtilizado)
      await this.loadItem()
      this.title = this.$online.clienteDireccion.title(this.item.dataset)
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectClienteDireccion(
          this,
          this.routeParams.idcliente_direccion,
          this.routeParams.idcliente,
          this.routeParams.idcliente_potencial
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
          this.toolbarOptions.map.visible = !!this.item.dataset.latitud && !!this.item.dataset.longitud
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickEdit () {
      if (this.tipo === 'cliente_potencial') {
        this.$appRouter.push({
          name: 'direcciones__clientes-potenciales-cliente-direccion-edit',
          params: {
            idcliente_direccion: this.routeParams.idcliente_direccion,
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (this.tipo === 'cliente') {
        this.$appRouter.push({
          name: 'direcciones__clientes-cliente-direccion-edit',
          params: {
            idcliente_direccion: this.routeParams.idcliente_direccion,
            idcliente: this.routeParams.idcliente,
          },
        })
      }
    },
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.latitud, this.item.dataset.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección "${this.item.title}"?`)
        if (res) {
          await this.$online.clienteDireccion.geolocalizar(
            this,
            this.routeParams.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
  }
}
</script>
