import { APIFilter } from '@/utils/api'
import _ from '@/utils/lodash'

export default {
  async selectClienteDireccion (Vue, idclienteDireccion, idcliente, idclientePotencial) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter.addExact('idcliente_direccion', idclienteDireccion)
    apiCalls.push({ name: 'selectClienteDireccion', method: 'clienteDireccion.select', params: { filter: apiFilter }})
    const apiFilterRelacionado = new APIFilter()
    apiFilterRelacionado.addGT('estado', 0)
    if (idclientePotencial) {
      apiFilterRelacionado.addExact('idcliente_potencial', idclientePotencial)
      apiCalls.push({ name: 'selectDatasetRelacionado', method: 'clientePotencial.select', params: { filter: apiFilterRelacionado } })
    } else {
      apiFilterRelacionado.addExact('idcliente', idcliente)
      apiCalls.push({ name: 'selectDatasetRelacionado', method: 'cliente.select', params: {filter: apiFilterRelacionado } })
    }
    const result =  await Vue.$api.batchCall(apiCalls)
    const datasetClienteDireccion = result.data.selectClienteDireccion.result.dataset[0]
    const datasetRelacionado = result.data.selectDatasetRelacionado.result.dataset[0]
    // solo el cliente potencial tiene dirección de servicio
    // no sé por qué tengo que poner [datasetClienteDireccion] entre corchetes, si no no lo encuentra
    if (datasetRelacionado) {
      if (idclientePotencial) {
        const indexDireccionServicio = _.findIndex(
          [datasetClienteDireccion], {
            idcliente_direccion:  datasetRelacionado.idcliente_direccion_servicio
          }
        )
        // findIndex devuelve -1 si no lo encuentra
        if (indexDireccionServicio >= 0) {
          result.data.selectClienteDireccion.result.dataset[indexDireccionServicio].servicio = true
        }
      }
      const indexDireccionFiscal = _.findIndex(
        [datasetClienteDireccion], {
          idcliente_direccion:  datasetRelacionado.idcliente_direccion
        }
      )
      if (indexDireccionFiscal >= 0) {
        result.data.selectClienteDireccion.result.dataset[indexDireccionFiscal].fiscal = true
      }
      const indexDireccionPostal = _.findIndex(
        [datasetClienteDireccion], {
          idcliente_direccion:  datasetRelacionado.idcliente_direccion_postal
        }
      )
      if (indexDireccionPostal >= 0) {
        result.data.selectClienteDireccion.result.dataset[indexDireccionPostal].postal = true
      }
    }
    return [result.data.selectClienteDireccion.result.dataset[0], result.data.selectClienteDireccion.result.metadata]
  },
}
